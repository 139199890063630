
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import { FilesProcess } from '@/components/forms/view/FilesProcess'
  import { Auto } from '@/entities/public'
  import { Stock } from '@/entities/sales'
  import { UNAVAILABLE_PHOTO } from '@/store/vehicles/vehicle'
  import { Debounce } from '@/utils/decorators'
  import { Appraisal } from '@/entities/purchase'
  import axios from 'axios'
  import { mapGetters } from 'vuex'

  const { VUE_APP_PUBLIC_URL } = process.env

  @Component({
    computed: {
      ...mapGetters('app', ['isMobile']),
    },
  })
  export default class StockPhotos extends FilesProcess {
  @Prop({ type: Boolean, default: false }) isPadding?: boolean;
  @Prop({ type: Boolean, default: true }) displayCarrousel?: boolean;
  @Prop({ type: Boolean, default: true }) displayReference?: boolean;
  @Prop({ type: Object, default: () => null }) auto?: Auto;
  @Prop({ type: Object, default: () => null }) stock?: Stock;
  @Prop({ type: Object, default: () => null }) appraisal?: Appraisal;
  @Prop({ type: Object, default: () => null }) item?: Record<string, any>;
  @Prop({ type: Boolean, default: true }) findStock?: boolean;
  @Prop({ type: Boolean, default: false }) fullImage?: boolean;
  @Prop({ type: Number, default: null }) idInspection?: number;
  @Prop({ type: Number, default: 360 }) width?: number;
  @Prop({ type: Number, default: 300 }) height?: number;
  @Prop({ type: Boolean, default: false }) notDisplayAppraisal!: boolean
  @Prop({ type: Boolean, default: false }) notDisplayVersion!: boolean
  @Prop({ type: Boolean, default: true }) showTitle!: boolean

  photo = UNAVAILABLE_PHOTO
  photos = [];
  photosAppraisal = [];
  photoInspection = [];
  versionYearPhoto = [];
  selectedPhotoIndex: number | string = 0
  selectedPhotoAppraisalIndex: number | string = 0
  imageAppraisalLoaded = false
    isMobile!: boolean

  beforeDestroy () {
    this.photos = []
    this.versionYearPhoto = []
  }

  get fixWidth () {
    const { fullImage, width, isMobile } = this

    if (isMobile && width > 420) {
      return 420
    } else if (isMobile && width < 420) {
      return width
    }

    return !fullImage ? width : '700'
  }

  get fixHeight () {
    const { fullImage, height, isMobile } = this
    if (isMobile && height > 420) {
      return 420
    } else if (isMobile && height < 420) {
      return height
    }

    return !fullImage ? height : '700'
  }

  get fixSliderSize () {
    const { fullImage } = this

    return !fullImage ? '40' : '120'
  }

  async mounted () {
    const { stock, item, auto, idInspection, appraisal } = this

    if (stock || item instanceof Stock) {
      await this.getPhotos(stock?.id || item?.id)
    }

    if (auto || item instanceof Auto) {
      await this.getStock(auto?.id || item?.id)
    }

    if (appraisal || item instanceof Appraisal) {
      await this.getPhotoAppraisal(appraisal?.id || item?.id)
    }

    if (!this.photos.length && idInspection) {
      await this.getPhotoInspection(idInspection)
    }

    if (!this.photos.length) {
      await this.findVersionYearPhoto()
    }
    this.$emit('photo-loaded')
  }

  async getStock (autoId) {
    if (!autoId) return

    const stockId = await this.findStockId(autoId)

    if (stockId) {
      await this.getPhotos(stockId)
    }
  }

  async findVersionYearPhoto () {
    const { auto, stock, item } = this
    let versionYear = null

    if (auto || item instanceof Auto) {
      versionYear = auto?.version?.idGenio
    } else if (stock || item instanceof Stock) {
      versionYear = stock?.auto?.version?.idGenio
    }

    if (!versionYear) return

    try {
      const { data } = await axios.get(`${VUE_APP_PUBLIC_URL}/external-access/api/version-year/get-picture-by-version-year/${versionYear}`)

      this.versionYearPhoto = this.mapFiles([{ file: { uri: data } }])
    } catch (error) {
      console.error('Sin imagen referencial')
    }
  }

  mapFiles (photos) {
    return photos.map(fileObj => ({
      id: fileObj?.file?.id,
      src: fileObj?.file?.uri,
      type: fileObj?.file?.type,
      name: fileObj?.file?.name,
      size: fileObj?.file?.checksum,
      idFileProcess: fileObj?.id,
      isFileExpired: fileObj?.file?.isFileExpired,
      sourceLink: fileObj?.file?.sourceLink,
      error: fileObj?.file?.error,
      smallSrc: fileObj?.file?.smallUrl,
      largeSrc: fileObj?.file?.largeUrl,
    }))
  }

  async getPhotoAppraisal (id) {
    if (!id) return

    const process = (await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'appraisal' } },
    }))[0]

    const file = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: id } },
          { parameter: { process: { id: { _eq: process?.id } } } },
          { parameter: { file_type: { name: { _eq: 'photo' } } } },
        ],
      },
      force: true,
    })

    this.photosAppraisal = this.mapFiles(file)
    this.$emit('photo-loaded')
  }

  async getPhotoInspection (id) {
    if (!id) return

    const identifier = (await this.fetchData({
      query: { name: 'findLite', model: 'ComponentCategory' },
      filter: { name: { _eq: 'Identificación' } },
    }))[0]

    const color = identifier.components.find(component => component.name === 'Color')

    const component = (await this.fetchData({
      query: { name: 'find', model: 'InspectionView' },
      filter: {
        _and: [
          { id_inspection: { _eq: id } },
          { id_inspection_component: { _eq: color.id } },
        ],
      },
      force: true,
    }))[0]

    const process = (await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'inspection_inspected_component' } },
    }))[0]

    if (component?.id) {
      const file = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: component.idInspectedComponent } },
            { parameter: { process: { id: { _eq: process?.id } } } },
            { parameter: { file_type: { name: { _eq: 'photo' } } } },
          ],
        },
        force: true,
      })

      this.photoInspection = this.mapFiles(file)
      this.$emit('photo-loaded')
    } else if (this.stock?.id && color?.inspectionComponent?.id) {
      const component = (await this.fetchData({

        query: { name: 'find', model: 'InspectionView' },
        filter: {
          _and: [
            { id_enablement: { _eq: this.stock.viewDetails.idEnablement } },
            { inspection_component: { id: { _eq: color.inspectionComponent.id } } },
          ],
        },
        force: true,
      }))[0]

      const file = await this.fetchData({
        query: { name: 'find', model: 'FileProcess' },
        filter: {
          _and: [
            { id_process_record: { _eq: component.idInspectedComponent } },
            { parameter: { process: { id: { _eq: process?.id } } } },
            { parameter: { file_type: { name: { _eq: 'photo' } } } },
          ],
        },
        force: true,
      })

      this.photoInspection = this.mapFiles(file)
      this.$emit('photo-loaded')
    }
  }

  async handleComponentChange (parameter, id): Promise<FilesProcess[]> {
    const photosFetched = await this.fetchData({
      query: { name: 'find', model: 'FileProcess' },
      filter: {
        _and: [
          { id_process_record: { _eq: id } },
          { id_file_parameter: { _eq: parameter.id } },
        ],
      },
      force: true,
    })

    if (photosFetched.length) {
      return photosFetched
    }

    return []
  }

  async getPhotos (id) {
    if (!id || this?.deal?.id) return

    const { displayCarrousel } = this
    const filter = {
      _and: [
        { process: { table_name: { _eq: 'stock' } } },
        { file_type: { name: { _eq: 'photo' } } },
        { name: { _eq: 'right_front' } },
      ],
    }

    if (displayCarrousel) {
      filter._and.pop()
    }

    const photos = await this.fetchData({
      query: { name: 'find', model: 'FileParameter' },
      filter,
      force: true,
    })

    const fileParametersPhoto = photos.sort((a, b) => a.visibility?.publications?.order - b.visibility?.publications?.order)

    const newPhotos = []

    for (const parameter of fileParametersPhoto) {
      const file = await this.handleComponentChange(parameter, id)

      if (file?.length) {
        newPhotos.push(...file)
      }
    }

    this.photos = this.mapFiles(newPhotos)
    this.$emit('photo-loaded')
  }

  get vehicle () {
    const { stock, auto, findStock } = this

    return {
      auto,
      stock,
      findStock,
    }
  }

  @Watch('vehicle', { immediate: true, deep: true })
  async onVehicleChange (val) {
    if (!val?.auto?.id && !val?.stock?.id) return

    if (!val?.findStock) {
      return
    }

    if (val?.auto?.id && val?.stock?.id) {
      await this.getPhotos(val.stock?.id)
    }

    if (!val?.stock?.id) {
      const stockId = await this.findStockId(val.auto.id)

      if (stockId) {
        await this.getPhotos(stockId)
      }
    }
  }

  @Watch('idInspection', { immediate: true, deep: true })
  @Debounce(1000)
  async onIdInspectionChange (val) {
    if (!val && !this.photos?.length) return

    await this.getPhotoInspection(val)
  }

  @Watch('appraisal', { immediate: true, deep: true })
  async onAppraisalChange (val) {
    if (!val && !this.photos?.length) return

    await this.getPhotoAppraisal(val?.id)
  }

  get isAppraisalPhoto () {
    const { photosAppraisal, selectedPhotoAppraisalIndex } = this
    return photosAppraisal?.[selectedPhotoAppraisalIndex]
  }

  onImageLoad () {
    this.imageAppraisalLoaded = true
  }
  }
