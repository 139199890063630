import { Component } from 'vue-property-decorator'

import { GForm } from '@/components/forms/GForm'
import { isValidNumber, parseToNumber } from '@/utils/general'

@Component
export class FinancialView extends GForm {
  closingReason = null
  statuses = {
    closed: null,
    preapproved: null,
    pending: null,
  }

  settings = null
  allow = false

  evaluationStatus = {
    isPending: { condition: null },
    isApproved: { condition: null, status: 'preapproved' },
    isConditioned: {
      condition: false,
      extraFilter: evaluation => evaluation?.conditions?.some(condition => condition.isIncreaseInitial),
    },
  }

  async mounted () {
    await this.findStatuses()

    this.settings = (await this.fetchData({
      query: { name: 'find', model: 'Process' },
      filter: { table_name: { _eq: 'financing' } },
    }))[0]
    this.allow = this.settings?.config?.multipleFinancing
  }

  async findStatuses () {
    const pending = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'evaluation' } } }, { status: { name: { _eq: 'pending' } } }] },
    })
    const closed = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'evaluation' } } }, { status: { name: { _eq: 'closed' } } }] },
    })
    const preapproved = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'evaluation' } } }, { status: { name: { _eq: 'preapproved' } } }] },
    })
    const closingReason = await this.fetchData({
      query: { name: 'find', model: 'ClosingReason' },
      filter: { _and: [{ type: { name: { _eq: 'new_request' } } }, { status: { process: { table_name: { _eq: 'evaluation' } } } }] },
    })

    this.statuses.preapproved = preapproved[0].id
    this.statuses.pending = pending[0].id
    this.statuses.closed = closed[0].id
    this.closingReason = closingReason[0].id
  }

  getTheFinancing (client, applicant, order) {
    let financings = []
    const isSameClient = client.id === applicant?.id

    financings = isSameClient ? this.getFinancings(client, order) : this.getFinancings(applicant, order)

    return financings
  }

  filterEvaluations (evaluations, pie, total, evaluationStatus, status) {
    if (!evaluations?.length) return []
    return evaluations.map(evaluation =>
      this.getEligibleEvaluation(
        evaluation,
        pie,
        total,
        evaluationStatus[status].condition,
        evaluationStatus[status].status
      )
    )
  }

  async insertNewFinancing (client, order, formData, total, deal) {
    const { applicant, pie, addTransfer } = formData
    const { evaluationStatus, allow } = this

    const financings = this.getTheFinancing(client, applicant, order)
    const evaluations = this.getEvaluations(financings)

    const eligibleEvaluations = []
    const deletableEvaluations = []

    for (const status in evaluationStatus) {
      let filteredEvaluations = evaluations?.filter(evaluation => evaluation.status[status])
      let allEvaluations = this.getEvaluations(this.getAllFinancing(order))?.filter(evaluation => evaluation.status[status])
      if (status === 'isConditioned' && evaluationStatus[status].extraFilter) {
        filteredEvaluations = filteredEvaluations?.filter(evaluationStatus[status].extraFilter)
        allEvaluations = allEvaluations?.filter(evaluationStatus[status].extraFilter)
      }

      if (filteredEvaluations?.length) {
        eligibleEvaluations.push(...this.filterEvaluations(filteredEvaluations, pie, total, evaluationStatus, status))
      }

      if (allEvaluations?.length) {
        deletableEvaluations.push(...this.filterEvaluations(allEvaluations, pie, total, evaluationStatus, status))
      }
    }

    if (!allow) {
      await this.cancelEvaluation(deletableEvaluations)
    }

    if (!evaluations?.length) {
      await this.createFinancing(order, pie.amount, total, applicant, addTransfer, deal)
      return
    }

    if (eligibleEvaluations.length) {
      const newPie = Math.round(parseToNumber(pie.amount))
      await this.createFinancingWithEvaluations(order, newPie, total, eligibleEvaluations, financings, formData)
    }
  }

  getEligibleEvaluation (evaluation, pie, total, condition, status) {
    const isInitialBigger = (total - pie.amount) > evaluation.financing.requestAmount

    if (isInitialBigger === condition || condition === null) {
      return {
        evaluation,
        status,
      }
    }
  }

  filteredEvaluation (evaluations, pie, total) {
    let isCreatable
    const evaluationStatus = {
      isPending: { condition: null },
      isApproved: { condition: null, status: 'preapproved' },
      isConditioned: {
        condition: false,
        extraFilter: evaluation => evaluation?.conditions?.some(condition => condition.isIncreaseInitial),
      },
    }

    for (const status in evaluationStatus) {
      let filteredEvaluations = evaluations.filter(evaluation => evaluation.status[status])

      if (status === 'isConditioned' && evaluationStatus[status].extraFilter) {
        filteredEvaluations = filteredEvaluations.filter(evaluationStatus[status].extraFilter)
      }

      isCreatable = filteredEvaluations.some(evaluation =>
        !this.isEvalCreatable(evaluation, pie, total, evaluationStatus[status].condition)
      )
      if (isCreatable) {
        return {
          status,
        }
      }
    }

    return isCreatable
  }

  isEvalCreatable (evaluation, pie, total, condition) {
    if (condition === null) return true
    const isInitialBigger = (total - pie.amount) > evaluation.financing.requestAmount

    if (isInitialBigger === condition) {
      return true
    }
  }

  async getEvaluationObject () {
    const financials = await this.fetchData({
      query: { name: 'find', model: 'Agreement' },
      filter: { _and: [{ active: { _eq: true } }, { type: { name: { _eq: 'financing' } } }] },
    })
    const statusEvaluation = await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: { _and: [{ process: { table_name: { _eq: 'evaluation' } } }, { status: { name: { _eq: 'pending' } } }] },
    })

    return Promise.all(financials.map(async person => {
      return {
        id_agreement: person.id,
        id_process_status: statusEvaluation[0].id,
      }
    }))
  }

  async getResponsible (financial) {
    const filter = { _and: [{ default: { _eq: true } }, { id_agreement: { _eq: financial.id } }] }

    const responsible = await this.fetchData({
      query: { name: 'find', model: 'ResponsibleAgreement' },
      filter,
    })
    return responsible[0].id
  }

  async createFinancing (order, initial, total, applicant, addTransfer, viewData) {
    if (!order?.saleOrderItems?.find(item => item.isProduct)) {
      await this.insertSalesOrderItemProduct(order.id, viewData.lead.sale.stock)
    }

    if (!order?.saleOrderItems?.find(item => item.isTransfer)) {
      await this.insertSalesOrderItemTransfer(order.id, viewData.lead.sale.stock)
    }

    if (isValidNumber(viewData?.discount) && viewData?.discount) {
      await this.insertUpdateSalesOrderItemDiscount({ discount: viewData.discount }, order.id, order?.saleOrderItems)
    }

    const financingFields = {
      evaluations: {
        data: await this.getEvaluationObject(),
      },
      id_applicant: applicant?.id,
      initial: Math.round(parseToNumber(initial)),
      id_sale_order: order.id,
      warranty_amount: total,
    }

    const financing = await this.pushData({ model: 'Financing', fields: { ...financingFields } })

    const items = await this.fetchData({
      query: { name: 'find', model: 'SaleOrderItem' },
      filter: { id_sale_order: { _eq: order.id } },
      force: true,
    })

    await this.insertFinancedItem(items, financing, { addTransfer })

    return financing
  }

  async insertFinancedItem (items, financing, { addTransfer }) {
    const orderItems = items.filter(item => item.isDiscount || item.isProduct || (item.isTransfer && addTransfer))

    await Promise.all(orderItems.map(async item => {
      const fields = {
        id_financing: financing.id,
        id_sale_order_item: item.id,
      }
      await this.pushData({ model: 'FinancedItem', fields })
    }))
  }

  async createFinancingWithEvaluations ({
    id,
    saleOrderItems,
  }, initial, total, eligibleEvaluations, financing, { addTransfer }) {
    const { statuses } = this
    const evaluationsData = eligibleEvaluations.map(({ evaluation, status }) => ({
      id_agreement: evaluation.agreement.id,
      id_process_status: this.statuses[status] || statuses.pending,
    }))

    const financingFields = {
      evaluations: {
        data: evaluationsData,
      },
      id_applicant: financing.applicant?.id,
      initial,
      id_sale_order: id,
      warranty_amount: total,
    }

    const newFinancing = await this.pushData({ model: 'Financing', fields: { ...financingFields } })
    await this.insertFinancedItem(saleOrderItems, newFinancing, { addTransfer })

    await this.cancelEvaluation(eligibleEvaluations)
  }

  async cancelEvaluation (eligibleEvaluations) {
    const { statuses, closingReason } = this
    const evaluationsPending = eligibleEvaluations.filter(elegible =>
      elegible?.evaluation?.status?.isPending).map(elegible => elegible?.evaluation?.id)

    if (evaluationsPending?.length) {
      await Promise.all(evaluationsPending.map(evaluationId => this.pushData({
        model: 'Evaluation',
        fields: {
          id: evaluationId,
          id_process_status: statuses.closed,
          id_closing_reason: closingReason,
        },
      })))
    }
  }

  getFinancings (client, order) {
    const financings = order.financings
      .filter(_ => _?.applicant?.id)
      .filter(financing => financing.applicant.id === client?.id)
      .filter(_ => _?.id)

    return this.sortAndGetLastFinancing(financings)
  }

  getAllFinancing (order) {
    return order.financings.filter(_ => _?.id)
  }

  getEvaluations (financings) {
    if (!Array.isArray(financings)) {
      return financings?.evaluations?.filter(evaluation => !evaluation.status.isRejected).filter(_ => _?.id)
    }

    return financings?.flatMap(financing =>
      financing?.evaluations?.filter(evaluation =>
        !evaluation.status.isRejected && evaluation?.id
      ) || []
    )
  }

  sortAndGetLastFinancing (financings) {
    return financings.sort((a, b) => {
      if (a.id == null) return -1

      if (b.id == null) return 1

      return b.id - a.id
    })[0]
  }

  async insertSalesOrderItemProduct (id, stock) {
    if (!stock?.price?.amount) return

    const productType = await this.fetchData({
      query: { name: 'find', model: 'ItemType' },
      filter: { name: { _eq: 'product' } },
    })

    const fields = {
      id_item_type: productType[0]?.id,
      quantity: 1,
      amount: stock?.price?.amount,
      id_sale_order: id,
    }

    await this.pushData({ model: 'SaleOrderItem', fields })
  }

  async insertSalesOrderItemTransfer (id, stock) {
    const transferType = await this.fetchData({
      query: { name: 'find', model: 'ItemType' },
      filter: { name: { _eq: 'transfer' } },
    })

    const fields = {
      id_item_type: transferType[0]?.id,
      quantity: 1,
      amount: stock?.transferCost,
      id_sale_order: id,
    }

    await this.pushData({ model: 'SaleOrderItem', fields })
  }

  async insertUpdateSalesOrderItemDiscount (formData, id, items) {
    const discount = items.filter(item => item.isDiscount)
    const discountType = await this.fetchData({
      query: { name: 'find', model: 'ItemType' },
      filter: { name: { _eq: 'discount' } },
    })

    const fields = {
      id_item_type: discountType[0]?.id,
      quantity: 1,
      amount: formData.discount,
      id_sale_order: id,
    }

    if (!discount?.length) {
      await this.pushData({ model: 'SaleOrderItem', fields })
    } else {
      await this.pushData({ model: 'SaleOrderItem', fields: { ...fields, id: discount[0].id } })
    }
  }
}
