import { render, staticRenderFns } from "./panel.vue?vue&type=template&id=2b152f27&scoped=true&"
import script from "./panel.vue?vue&type=script&lang=ts&"
export * from "./panel.vue?vue&type=script&lang=ts&"
import style0 from "./panel.vue?vue&type=style&index=0&id=2b152f27&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b152f27",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VChip,VCol,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VIcon,VTooltip})
